






















import { Component, Vue } from 'vue-property-decorator'
import { BigNumber } from 'bignumber.js'
import { Currency } from '@/types'
import CurrencyInput from '@/components/CurrencyInput.vue'

@Component({
	components: {
	  CurrencyInput
	},
})
export default class Internal extends Vue {

	currency = 'IBXE' as Currency
	loading = true
	max = new BigNumber(300.00019)
	precision = 4
	quantity = new BigNumber(42)

	private async mounted(): Promise<void> {
		this.loading = false
	}

}
